<template>
    <v-container>
        <v-layout column class="panel-wrapper ma-5 pa-5" style="background-color: #fff">
            <v-flex xs12 style="text-align: center">
                <div>
                    <img src="./_source/processing.png" alt="Pending Client" style="width: 150px;height: 150px;">
                </div>
                <h1 class="my-3"
                    v-text="process==='1'?'Complete Your Billing Information':'Identity Verification'"></h1>
                <v-stepper alt-labels class="elevation-0" :value="process">
                    <v-stepper-header>
                        <v-stepper-step step="1" :complete="process>'1'">Completing</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="2" :complete="process>'2'">Ordering</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="3" :complete="process>'3'">Verifying</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="4">Deploying</v-stepper-step>
                    </v-stepper-header>
                </v-stepper>
                <!--<p>It may take 1-2 hours. Please check for your invoice later.</p>-->
                <div>
                    <p v-if="process==='3'" style="color: #333;">For security reasons, we need to carry out our identity verification process manually before the service. It usually takes 1-2 hours. Thanks for your patience.</p>
                    <p style="text-align: left;display: inline-block;color: #666">
<!--                        Renew your service by the following steps:<br/>-->
                        Step 1:  Complete your billing information.Typically, we will verify your information within 2 hours. <br>
                        Step 2:  Place an order.<br>
                        Step 3:  Identity verification processing. (1-2 hours by Billing Staff)<br>
                        Step 4:  Your application is automatically deployed.(3-5 minutes)
                    </p>
                </div>

                <v-btn v-if="process==='3'" class="my-3 primary" to="/applications">Check My Application</v-btn>
                <template v-if="process==='1'">
                    <v-btn  class="my-3 text-none" color="blue darken-1" flat to="/">Later</v-btn>
                    <v-btn
                            class="my-3 primary text-none" :to="`profile/account?return_url=${$route.query.return_url}`">Continue
                    </v-btn>
                </template>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: "processing",
        data() {
            return {
                process: 1
            }
        },
        created() {
            this.process = this.$route.query.processing
        }
    }
</script>


