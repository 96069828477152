var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-layout",
        {
          staticClass: "panel-wrapper ma-5 pa-5",
          staticStyle: { "background-color": "#fff" },
          attrs: { column: "" }
        },
        [
          _c(
            "v-flex",
            { staticStyle: { "text-align": "center" }, attrs: { xs12: "" } },
            [
              _c("div", [
                _c("img", {
                  staticStyle: { width: "150px", height: "150px" },
                  attrs: {
                    src: require("./_source/processing.png"),
                    alt: "Pending Client"
                  }
                })
              ]),
              _c("h1", {
                staticClass: "my-3",
                domProps: {
                  textContent: _vm._s(
                    _vm.process === "1"
                      ? "Complete Your Billing Information"
                      : "Identity Verification"
                  )
                }
              }),
              _c(
                "v-stepper",
                {
                  staticClass: "elevation-0",
                  attrs: { "alt-labels": "", value: _vm.process }
                },
                [
                  _c(
                    "v-stepper-header",
                    [
                      _c(
                        "v-stepper-step",
                        { attrs: { step: "1", complete: _vm.process > "1" } },
                        [_vm._v("Completing")]
                      ),
                      _c("v-divider"),
                      _c(
                        "v-stepper-step",
                        { attrs: { step: "2", complete: _vm.process > "2" } },
                        [_vm._v("Ordering")]
                      ),
                      _c("v-divider"),
                      _c(
                        "v-stepper-step",
                        { attrs: { step: "3", complete: _vm.process > "3" } },
                        [_vm._v("Verifying")]
                      ),
                      _c("v-divider"),
                      _c("v-stepper-step", { attrs: { step: "4" } }, [
                        _vm._v("Deploying")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", [
                _vm.process === "3"
                  ? _c("p", { staticStyle: { color: "#333" } }, [
                      _vm._v(
                        "For security reasons, we need to carry out our identity verification process manually before the service. It usually takes 1-2 hours. Thanks for your patience."
                      )
                    ])
                  : _vm._e(),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "text-align": "left",
                      display: "inline-block",
                      color: "#666"
                    }
                  },
                  [
                    _vm._v(
                      "\n                        Step 1:  Complete your billing information.Typically, we will verify your information within 2 hours. "
                    ),
                    _c("br"),
                    _vm._v(
                      "\n                        Step 2:  Place an order."
                    ),
                    _c("br"),
                    _vm._v(
                      "\n                        Step 3:  Identity verification processing. (1-2 hours by Billing Staff)"
                    ),
                    _c("br"),
                    _vm._v(
                      "\n                        Step 4:  Your application is automatically deployed.(3-5 minutes)\n                    "
                    )
                  ]
                )
              ]),
              _vm.process === "3"
                ? _c(
                    "v-btn",
                    {
                      staticClass: "my-3 primary",
                      attrs: { to: "/applications" }
                    },
                    [_vm._v("Check My Application")]
                  )
                : _vm._e(),
              _vm.process === "1"
                ? [
                    _c(
                      "v-btn",
                      {
                        staticClass: "my-3 text-none",
                        attrs: { color: "blue darken-1", flat: "", to: "/" }
                      },
                      [_vm._v("Later")]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "my-3 primary text-none",
                        attrs: {
                          to:
                            "profile/account?return_url=" +
                            _vm.$route.query.return_url
                        }
                      },
                      [_vm._v("Continue\n                    ")]
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }